import React from 'react';
import { Link } from 'gatsby';
import Seo from '../components/Seo';
import { contactHolder } from './contact.module.scss';

export default function ContactPage() {
  return (
    <article className={contactHolder}>
      <h2>Contact</h2>
      <div className="contactInformation">
        <a href="mailto:info@thecreatives.org" title="Email The Creatives">
          <span>info@thecreatives.org</span>
        </a>
      </div>
    </article>
  );
}

export const Head = () => <Seo title="Contact" />;
